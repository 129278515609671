import { Button, Container, Overlay, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import classes from "./HomeHero.module.css";

export function HomeHero({
  title,
  body,
  image,
}: {
  title?: string;
  body?: string;
  image?: string;
}) {
  const { t } = useTranslation("home");
  return (
    <div className={classes.hero}>
      <Image
        alt={title || t("title")}
        src={image || "/assets/images/hero.jpg"}
        quality={80}
        priority
        fill
        sizes="100vw"
        style={{ objectFit: "cover" }}
      />
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={classes.container}>
        <Stack gap="xl">
          <Title className={classes.title} order={2}>
            {title || t("title")}
          </Title>
          <Text className={classes.description} size="xl">
            {body || t("body")}
          </Text>
          <Button
            component={Link}
            href="/login?state=register"
            size="xl"
            radius="xl"
            className={classes.control}
          >
            {t("getStarted")}
          </Button>
        </Stack>
      </Container>
    </div>
  );
}
