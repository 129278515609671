import {
  Anchor,
  Box,
  Card,
  Grid,
  Group,
  Loader,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconTrophy } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { listBoards, listMatches } from "../../common/api";
import { Board } from "../../common/boards/board.schema";
import { Event } from "../../common/events/event.schema";
import { Member } from "../../common/members/member.schema";
import { getBoardLink, getEventLink, getRecordId } from "../../common/util";
import Hider from "../common/Hider";
import RecordAvatar from "../common/RecordAvatar";
import MemberDisplay from "../members/MemberDisplay";
import Link from "next/link";
const LIVE_TIME_CUTOFF = 1000 * 60 * 60 * 1; // 1 hour
const LIVE_POLL_INTERVAL = 1000 * 10; // 5 seconds
const DISPLAY_LIMIT = 5;
const LiveMatches = ({
  board,
  event,
  featuredOnly,
  liveOnly,
  mine,
  limit = DISPLAY_LIMIT,
}: {
  board?: Board;
  event?: Event;
  featuredOnly?: boolean;
  liveOnly?: boolean;
  mine?: boolean;
  limit?: number;
}) => {
  const timeCutoff = new Date().getTime() - LIVE_TIME_CUTOFF;
  const { data: boards } = useQuery(
    ["featured-recent", mine],
    () =>
      listBoards({
        params: mine ? { mine: true } : { featured: true },
      }),
    { enabled: featuredOnly }
  );
  let boardIds: string[] = [];
  if (board) boardIds.push(board._id);
  if (boards?.records?.length) boardIds = boards.records.map((b) => b._id);
  const { data: liveMatcRes } = useQuery(
    ["live-matches", board, event, featuredOnly, mine],
    () =>
      listMatches({
        params: {
          board: boardIds,
          tournament: event?._id,
          updatedAt: { $gte: timeCutoff },
        },
        populate: ["members", "board", "tournament"],
        sort: "-updatedAt",
      }),
    {
      enabled: !!boardIds.length,
      refetchInterval: LIVE_POLL_INTERVAL,
    }
  );
  const { data: completeMatchRes } = useQuery(
    ["complete-matches", board, event, featuredOnly, mine],
    () =>
      listMatches({
        params: {
          board: boardIds,
          tournament: event?._id,
          complete: true,
        },
        populate: ["members", "board", "tournament"],
        sort: "-updatedAt",
      }),
    {
      enabled: !!boardIds.length && !liveOnly,
    }
  );
  let title = "Live match results";
  //   console.log("data :", data);
  let matches = liveMatcRes?.records || [];
  const draftCount = matches.filter((m) => m.draft).length;
  if (draftCount === 0) title = "Recent match results";
  if (completeMatchRes?.records.length) {
    completeMatchRes.records.forEach((match) => {
      if (!matches?.find((m) => m._id === match._id)) {
        matches?.push(match);
      }
    });
  }
  if (liveOnly) matches = matches.filter((m) => !m.complete);
  //   console.log("matches :", matches);
  const { t } = useTranslation("common");
  const existingMembers: string[] = [];
  //   console.log("existingMembers :", existingMembers);
  const displayMatches = matches
    ?.filter((match) => {
      if (match.members.length !== 2) return false;
      const a = match.members[0];
      const b = match.members[1];
      if (event && event.complete && match.draft) return false; // dont show in progress matches if event is complete
      if (event && event.poolsComplete && match.pool && match.draft)
        return false; // dont show in progress matches if pools are complete
      if (!a || !b) return false;
      if (existingMembers.includes(getRecordId(a))) return false;
      existingMembers.push(getRecordId(a));
      if (existingMembers.includes(getRecordId(b))) return false;
      existingMembers.push(getRecordId(b));
      return true;
    })
    .slice(0, limit);
  //   console.log("displayMatches :", displayMatches);
  if (displayMatches?.length === 0) return null;
  return (
    <Stack mb={20}>
      <Title size="sm" order={3}>
        {title}
      </Title>
      {displayMatches?.map((match) => {
        const a = match.members[0];
        const b = match.members[1];
        let aPoints = match.points ? match.points[0] : 0;
        let bPoints = match.points ? match.points[1] : 0;
        if (match.complete) {
          const winnerPoints = match.winnerPoints || 0;
          const loserPoints = match.loserPoints || 0;
          aPoints =
            match.winner === getRecordId(a) ? winnerPoints : loserPoints;
          bPoints =
            match.winner === getRecordId(b) ? winnerPoints : loserPoints;
        }
        const hasPoints = !!aPoints || !!bPoints;
        const showLink = !board || (!event && !!match.tournament);
        // console.log("match.tournament :", match.tournament);
        // console.log("event :", event);
        // console.log("board :", board);
        // console.log("showLink :", showLink);
        const matchBoard = match.board as Board;
        const matchEvent = match.tournament as Event;
        let link = getBoardLink(matchBoard);
        let linkName = `in ${matchBoard.name}`;
        if (matchEvent && !board) {
          link = getEventLink(matchBoard, matchEvent);
          linkName = `in ${matchBoard.name}: ${matchEvent.name}`;
        }
        if (matchEvent && board) {
          link = getEventLink(matchBoard, matchEvent);
          linkName = `in ${matchEvent.name}`;
        }
        return (
          <Card key={match._id}>
            <Stack>
              <Grid>
                <Grid.Col span={5}>
                  <Stack>
                    <Group justify="right">
                      <Hider smallerThan="sm">
                        <Box>
                          {match.winner === getRecordId(a) && (
                            <ThemeIcon size="sm" variant="light">
                              <IconTrophy size={12} />
                            </ThemeIcon>
                          )}
                        </Box>
                      </Hider>
                      <MemberDisplay
                        maxXS={12}
                        size="sm"
                        member={a}
                        position="right"
                      />
                      <RecordAvatar size="sm" record={a as Member} />
                      <Hider smallerThan="sm">
                        <Box>
                          {hasPoints && (
                            <ThemeIcon size="md" color="green" variant="light">
                              <Text fz="sm">{aPoints}</Text>
                            </ThemeIcon>
                          )}
                        </Box>
                      </Hider>
                    </Group>

                    <Hider largerThan="sm">
                      <Group justify="right">
                        {match.winner === getRecordId(a) && (
                          <ThemeIcon size="sm" variant="light">
                            <IconTrophy size={12} />
                          </ThemeIcon>
                        )}
                        {hasPoints && (
                          <ThemeIcon size="md" color="green" variant="light">
                            <Text fz="sm">{aPoints}</Text>
                          </ThemeIcon>
                        )}
                      </Group>
                    </Hider>
                  </Stack>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Group justify="center">
                    <ThemeIcon size="sm" variant="light">
                      <Text fz="sm">{t("vs")}</Text>
                    </ThemeIcon>
                  </Group>
                </Grid.Col>
                <Grid.Col span={5}>
                  <Stack>
                    <Group>
                      <Hider smallerThan="md">
                        <Box>
                          {hasPoints && (
                            <ThemeIcon size="sm" color="red" variant="light">
                              <Text fz="sm">{bPoints}</Text>
                            </ThemeIcon>
                          )}
                        </Box>
                      </Hider>
                      <RecordAvatar size="sm" record={b as Member} />
                      <MemberDisplay maxXS={12} size="sm" member={b} />
                      <Hider smallerThan="sm">
                        <Box>
                          {match.winner === getRecordId(b) && (
                            <ThemeIcon size="sm" variant="light">
                              <IconTrophy size={12} />
                            </ThemeIcon>
                          )}
                        </Box>
                      </Hider>
                    </Group>
                    <Hider largerThan="sm">
                      <Group>
                        {hasPoints && (
                          <ThemeIcon size="md" color="red" variant="light">
                            <Text fz="sm">{bPoints}</Text>
                          </ThemeIcon>
                        )}
                        {match.winner === getRecordId(b) && (
                          <ThemeIcon size="sm" variant="light">
                            <IconTrophy size={12} />
                          </ThemeIcon>
                        )}
                      </Group>
                    </Hider>
                  </Stack>
                </Grid.Col>
              </Grid>
              {!match.complete && (
                <Loader
                  pos="absolute"
                  right={10}
                  top={10}
                  size="xs"
                  type="dots"
                />
              )}
              {showLink && (
                <Anchor ta="right" component={Link} href={link} size="xs">
                  {linkName}
                </Anchor>
              )}
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
};
export default LiveMatches;
