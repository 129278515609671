import {
  Avatar,
  Box,
  BoxComponentProps,
  MantineColor,
  MantineSize,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { Record } from "../../common/record.schema";
import { getColor, getInitials } from "../../common/util";
const RecordAvatar = ({
  record,
  size,
  defaultColor,
  defaultIcon,
  ...others
}: BoxComponentProps & {
  record?: Record;
  size?: MantineSize;
  defaultColor?: MantineColor;
  defaultIcon?: React.ReactNode;
}) => {
  const r = record as any;
  let name = r?.name || "";
  if (r && r.lastName && r.lastName.length > 0) {
    name = `${name} ${r.lastName}`;
  }
  let display = name?.length ? getInitials(name) : defaultIcon;
  if (display === "") display = defaultIcon;
  // console.log("name :", name);
  // console.log("display :", display);
  const theme = useMantineTheme();
  return (
    <Box {...others}>
      <Avatar
        radius="sm"
        src={r?.image}
        alt={name}
        color={
          record?._id
            ? getColor(record._id)
            : defaultColor || theme.primaryColor
        }
        size={size}
      >
        {display}
      </Avatar>
    </Box>
  );
};
export default RecordAvatar;
