import {
  Anchor,
  Group,
  GroupProps,
  MantineFontSize,
  MantineSize,
  Stack,
  StyleProp,
  Text,
} from "@mantine/core";
import Link from "next/link";
import { CircleFlag } from "react-circle-flags";
import { useBoard, useMemberById } from "../../common/boards/board.hooks";
import { Member } from "../../common/members/member.schema";
import { getMemberLink } from "../../common/util";
import RecordAvatar from "../common/RecordAvatar";
import MemberName from "./MemberName";

const MemberDisplay = ({
  member: m,
  size,
  textSize,
  link,
  position,
  max,
  avatar,
  maxXS,
  club,
  flag,
  ...others
}: GroupProps & {
  member: Member | string;
  size?: MantineSize;
  textSize?: StyleProp<
    MantineFontSize | `h${1 | 2 | 3 | 4 | 5 | 6}` | number | string
  >;
  link?: boolean;
  avatar?: boolean;
  club?: boolean;
  flag?: boolean;
  position?: React.CSSProperties["alignItems"];
  max?: number;
  maxXS?: number;
}) => {
  const { board } = useBoard();
  const { member } = useMemberById(m);
  textSize = textSize || size;
  // console.log("member :", member);
  // console.log("typeof member :", typeof member);
  if (max && !maxXS) maxXS = max;
  if (!member) return <></>;
  const innerContent = (
    <Group wrap="nowrap" align={position}>
      {avatar && <RecordAvatar size={size} record={member} />}
      <Stack>
        <Group>
          <Text visibleFrom="xs" className="name" fz={textSize}>
            <MemberName member={member} max={max} />{" "}
          </Text>
          <Text hiddenFrom="xs" className="name" fz={textSize}>
            <MemberName member={member} max={maxXS} />{" "}
          </Text>
          {flag && member.country && (
            <CircleFlag
              countryCode={member.country.toLowerCase()}
              height={20}
            />
          )}
        </Group>
        {club && member.club && <Text size="xs">{member.club}</Text>}
      </Stack>
    </Group>
  );
  if (!board || !link) return <Group {...others}>{innerContent}</Group>;
  return (
    <Group {...others}>
      <Anchor
        component={Link}
        href={getMemberLink(board, member)}
        className="member-display"
      >
        {innerContent}
      </Anchor>
    </Group>
  );
};
export default MemberDisplay;
