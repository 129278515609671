import {
  Anchor,
  Badge,
  Card,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { IconListNumbers, IconUsers } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import TimeAgo from "react-timeago-i18n";
import { Epee } from "../../common/icons/epee";
import { Foil } from "../../common/icons/foil";
import { Longsword } from "../../common/icons/longsword";
import { Rapier } from "../../common/icons/rapier";
import { RapierDagger } from "../../common/icons/rapier_dagger";
import { Sabre } from "../../common/icons/sabre";
import { SwordBuckler } from "../../common/icons/sword_buckler";
import { Board } from "../../common/boards/board.schema";
import { Swords } from "../../common/icons/swords";
import { getBoardLink, getHasWeapons } from "../../common/util";
import RecordAvatar from "../common/RecordAvatar";
import { Weapon } from "../../common/sports";
import classes from "./BoardCard.module.css";

const BoardCard = ({
  board,
  showDates,
}: {
  board: Board;
  showDates?: boolean;
}) => {
  const { t } = useTranslation();
  let Icon = Swords;
  let tooltip = "Mixed weapons";
  if (board.sport && getHasWeapons(board.sport)) {
    switch (board.weapon) {
      case Weapon.EPEE:
        Icon = Epee;
        tooltip = "Épée";
        break;
      case Weapon.FOIL:
        Icon = Foil;
        tooltip = "Foil";
        break;
      case Weapon.SABRE:
        Icon = Sabre;
        tooltip = "Sabre";
        break;
      case Weapon.HEMA_LONGSWORD:
        Icon = Longsword;
        tooltip = "Longsword (HEMA)";
        break;
      case Weapon.HEMA_RAPIER:
        Icon = Rapier;
        tooltip = "Rapier (HEMA)";
        break;
      case Weapon.HEMA_SABRE:
        Icon = Sabre;
        tooltip = "Sabre (HEMA)";
        break;
      case Weapon.HEMA_RAPIER_DAGGER:
        Icon = RapierDagger;
        tooltip = "Rapier & dagger (HEMA)";
        break;
      case Weapon.HEMA_SWORD_BUCKLER:
        Icon = SwordBuckler;
        tooltip = "Sword & buckler (HEMA)";
        break;
    }
  }
  return (
    <Card key={board._id} pos="relative">
      <Stack>
        <Group wrap="nowrap" pr={70}>
          <RecordAvatar
            record={board}
            defaultIcon={<IconListNumbers />}
            // component={Link}
            // href={getBoardLink(board)}
            // src={board.image}
            // alt={board.name}
            // color={getColor(board._id)}
          />
          <Anchor
            component={Link}
            href={getBoardLink(board)}
            className={classes.link}
          >
            {board.name}
          </Anchor>
        </Group>
        {showDates && (
          <Stack gap={4}>
            <Text size="xs">
              {t("createdAt")} <TimeAgo date={board.createdAt} />
            </Text>
            {board.lastMatch && (
              <Text size="xs">
                {t("lastMatchAt")} <TimeAgo date={board.lastMatch} />
              </Text>
            )}
          </Stack>
        )}
      </Stack>
      <Group wrap="nowrap" gap={10} mih={48} right={10} top={12} pos="absolute">
        <Stack gap={10}>
          {board.memberCount !== 0 && board.memberCount && (
            <Badge
              size="xs"
              radius="xl"
              color="violet"
              variant="light"
              rightSection={
                <ThemeIcon mt={5} color="violet" variant="light" size={12}>
                  <IconUsers size={13} />
                </ThemeIcon>
              }
            >
              {board.memberCount}
            </Badge>
          )}
          {board.matchCount !== 0 && board.matchCount && (
            <Badge
              size="xs"
              radius="xl"
              color="green"
              variant="light"
              rightSection={
                <ThemeIcon mt={3} color="green" variant="light" size={12}>
                  <Swords />
                </ThemeIcon>
              }
            >
              {board.matchCount}
            </Badge>
          )}
        </Stack>
        <Tooltip label={tooltip}>
          <ThemeIcon variant="light">
            <Icon size={14} />
          </ThemeIcon>
        </Tooltip>
      </Group>
    </Card>
  );
};
export default BoardCard;
